import { AppstoreOutlined, MailOutlined } from '@ant-design/icons';
import { Menu, type MenuProps } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const items: MenuItem[] = [
	{
		label: '水电用量',
		key: 'checkUsed',
		// icon: <MailOutlined />,
	},
	{
		label: '水电读数',
		key: 'checkRead',
		// icon: <AppstoreOutlined />,
	},
];
export function ReadNavigation() {
	const navigate = useNavigate();
	const onClick: MenuProps['onClick'] = (e) => {
		navigate(`/apartment/${e.key}`);
	};

	return (
		<Menu
			onClick={onClick}
			selectedKeys={[items.find((i) => location.href.includes(i.key))?.key]}
			mode="horizontal"
			items={items}
		/>
	);
}
